import React, { Component, Fragment } from 'react';

import qs from 'qs';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Hits,
  SearchBox,
  Configure,
  Breadcrumb,
  MenuSelect,
  InfiniteHits,
  RefinementList,
  ClearRefinements,
  SortBy,
} from 'react-instantsearch-dom';
import {
  GeoSearch,
  CustomMarker,
  GoogleMapsLoader,
} from 'react-instantsearch-dom-maps';
import Slider from "react-slick";

import { array, bool, func, oneOf, object, shape, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import { orderBy } from 'lodash';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { withViewport } from '../../util/contextHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { createSlug, parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { AlgoliaAutoComplete } from './AlgoliaAutocomplete';
import { AlgoliaAutoItem } from './AlgoliaAutoItem';

import {
  Page,
  ListingCard,
  IconProfileSetup,
  Breadcrumbs,
  TabNav,
  IconMapMarker,
  SliderTiles,
  NamedLink,
  Footer,
  ResponsiveImage,
  SkeletonLoader,
  AlgoliaAutoCompleteSearchField,
  Button,
  SearchMapInfoCard
} from '../../components';
import { TopbarContainer } from '..';

import { searchMapListings, setActiveListing } from './AlgoliaSearchPage.duck';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from './AlgoliaSearchPage.helpers';
import { addOrRemoveToConnected, fetchCurrentCategories } from '../../ducks/user.duck';

import css from './AlgoliaSearchPage.module.css';
import { stringFromLength } from '../../util/data';
import CategoryDropdown from '../../components/SectionHero/CategoryDropdown';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.
const MAX_MOBILE_SCREEN_WIDTH = 1024;

const indexName = process.env.REACT_APP_ALGOLIA_LISTING_INDEX.toString();

// REACT_APP_ALGOLIA_SEARCH_API_KEY=1ebc78ee2bf3e4f93836bb22062a56da
// REACT_APP_ALGOLIA_API_KEY=dcd8686643e010baa4b39e3ff7f25e57
// REACT_APP_ALGOLIA_APP_ID=RLLWA1PPHN

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY,
  // 'latency',
  // '6be0576ff61c053d5f9a3225e2a90f76',
  {
    _useRequestCache: true,
  }
);

const updateAfter = 700;
const searchStateToUrl = (searchState) =>
  searchState ? `${window.location.pathname}?${qs.stringify(searchState)}` : '';

const isMobile = typeof window !== 'undefined' && window.innerWidth < 920;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
      <div
          className={className}
          style={{ ...style, display: 'block' }}
          onClick={onClick}
      >
          <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
              <rect
                  x="0.5"
                  y="-0.5"
                  width="35"
                  height="35"
                  rx="17.5"
                  transform="matrix(1 0 0 -1 0 35)"
                  fill="#212121"
                  stroke="#212121"
              />
              <path
                  d="M20.3372 22.3274C20.2945 22.3786 20.2416 22.4204 20.1818 22.45C20.122 22.4796 20.0567 22.4965 19.9901 22.4995C19.9235 22.5025 19.8569 22.4916 19.7947 22.4675C19.7325 22.4434 19.6761 22.4065 19.6289 22.3594C19.5817 22.3122 19.5449 22.2557 19.5208 22.1935C19.4966 22.1313 19.4857 22.0648 19.4887 21.9981C19.4917 21.9315 19.5086 21.8662 19.5382 21.8065C19.5679 21.7467 19.6097 21.6938 19.6609 21.651L23.1115 18.1964H11.706C11.5829 18.1899 11.467 18.1364 11.3821 18.0469C11.2973 17.9574 11.25 17.8388 11.25 17.7155C11.25 17.5922 11.2973 17.4736 11.3821 17.3842C11.467 17.2947 11.5829 17.2412 11.706 17.2347H23.1115L19.6555 13.7841C19.5685 13.693 19.52 13.5719 19.52 13.4459C19.52 13.32 19.5685 13.1989 19.6555 13.1078C19.6998 13.0633 19.7525 13.028 19.8106 13.0039C19.8686 12.9798 19.9308 12.9674 19.9936 12.9674C20.0565 12.9674 20.1187 12.9798 20.1767 13.0039C20.2347 13.028 20.2874 13.0633 20.3318 13.1078L24.6061 17.3821C24.6516 17.4259 24.6878 17.4784 24.7125 17.5365C24.7373 17.5946 24.75 17.6571 24.75 17.7203C24.75 17.7834 24.7373 17.8459 24.7125 17.904C24.6878 17.9621 24.6516 18.0146 24.6061 18.0584L20.3372 22.3274Z"
                  fill="#ffffff"
                  stroke="#ffffff"
              />
          </svg>
      </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;

  return (
      <div
          className={className}
          style={{ ...style, display: 'block' }}
          onClick={onClick}
      >
          <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
              <rect
                  width="36"
                  height="36"
                  rx="18"
                  transform="matrix(-1 -1.74846e-07 -1.74846e-07 1 36 0)"
                  fill="#212121"
              />
              <path
                  d="M15.6628 13.6726C15.7055 13.6214 15.7584 13.5796 15.8182 13.55C15.878 13.5204 15.9433 13.5035 16.0099 13.5005C16.0765 13.4975 16.1431 13.5084 16.2053 13.5325C16.2675 13.5566 16.3239 13.5935 16.3711 13.6406C16.4183 13.6878 16.4551 13.7443 16.4792 13.8065C16.5034 13.8687 16.5143 13.9352 16.5113 14.0019C16.5083 14.0685 16.4914 14.1338 16.4618 14.1935C16.4321 14.2533 16.3903 14.3062 16.3391 14.349L12.8885 17.8036L24.294 17.8036C24.4171 17.8101 24.533 17.8636 24.6179 17.9531C24.7027 18.0426 24.75 18.1612 24.75 18.2845C24.75 18.4078 24.7027 18.5264 24.6179 18.6158C24.533 18.7053 24.4171 18.7588 24.294 18.7653L12.8885 18.7653L16.3445 22.2159C16.4315 22.307 16.48 22.4281 16.48 22.5541C16.48 22.68 16.4315 22.8011 16.3445 22.8922C16.3002 22.9367 16.2475 22.972 16.1894 22.9961C16.1314 23.0202 16.0692 23.0326 16.0064 23.0326C15.9435 23.0326 15.8813 23.0202 15.8233 22.9961C15.7653 22.972 15.7126 22.9367 15.6682 22.8922L11.3939 18.6179C11.3484 18.5741 11.3122 18.5216 11.2875 18.4635C11.2627 18.4054 11.25 18.3429 11.25 18.2797C11.25 18.2166 11.2627 18.1541 11.2875 18.096C11.3122 18.0379 11.3484 17.9854 11.3939 17.9416L15.6628 13.6726Z"
                  fill="white"
                  stroke="white"
              />
          </svg>
      </div>
  );
}

export class AlgoliaSearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
      showMap: true,
      searchInURL: '',
      dropDownOpen: false,
      totalCount: 0,
      rangeValue: [config.rangeValue],
      searchState: typeof window !== 'undefined' && qs.parse(window.location.search.slice(1)),
      toggle: false,
      selectedCategory: 0
    };

    this.searchMapListingsInProgress = false;

    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    typeof window !== 'undefined' && window.addEventListener('popstate', ({ state: searchState }) => {
      this.setState({ searchState });
    });
  }

  componentDidUpdate() {
    if (typeof window != undefined) {
      setTimeout(() => {
        const searchInURL = parse(window.location.search);
        if (searchInURL) {
          if (this.state.searchInURL && (searchInURL['refinementList[categoriesLabel][0]'] && searchInURL['refinementList[categoriesLabel][0]'] == this.state.searchInURL['refinementList[categoriesLabel][0]']) || (searchInURL['refinementList[subCategoriesLabel][0]'] && searchInURL['refinementList[subCategoriesLabel][0]'] == this.state.searchInURL['refinementList[subCategoriesLabel][0]'])) {
            // do nothing
          } else if (!this.state.searchInURL && (searchInURL['refinementList[categoriesLabel][0]'] && searchInURL['refinementList[categoriesLabel][0]'] != this.state.searchInURL['refinementList[categoriesLabel][0]']) || (searchInURL['refinementList[subCategoriesLabel][0]'] && searchInURL['refinementList[subCategoriesLabel][0]'] != this.state.searchInURL['refinementList[subCategoriesLabel][0]'])) {
            this.setState({ searchInURL: { ...searchInURL } });
          } else if (this.state.searchInURL) {
            this.setState({ searchInURL: '' });
          }
        }
      }, 1000);
    }
  }

  componentWillUnmount() {
    this.setState({ searchInURL: '' });
  }

  handleToggleState = () => {
    this.setState({ toggle: !this.state.toggle });
  };

  onSearchStateChange = (searchState) => {
    // update the URL when there is a new search state.
    clearTimeout(this.debouncedSetState);
    this.debouncedSetState = setTimeout(() => {
      typeof window !== 'undefined' && window.history.pushState(
        searchState,
        null,
        searchStateToUrl(searchState)
      );
    }, updateAfter);

    this.setState((previousState) => {
      const hasQueryChanged =
        previousState.searchState.query !== searchState.query;

      return {
        ...previousState,
        searchState: {
          ...searchState,
          boundingBox: !hasQueryChanged ? searchState.boundingBox : null,
        },
      };
    });
  };

  handleSubmit(values) {
    const currentSearchParams = this.state.currentQueryParams;
    const { search, selectedPlace } = values.location;
    const { history } = this.props;
    const { origin, bounds } = selectedPlace;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = Object.assign(currentSearchParams, {
      bounds,
      address: search,
    });
    // const searchParams = {
    //   ...currentSearchParams,
    //   ...originMaybe,
    //   address: search,
    //   bounds,
    // }
    history.push(
      createResourceLocatorString(
        'AlgoliaSearchPage',
        routeConfiguration(),
        {},
        searchParams
      )
    );
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = routeConfiguration();
    const AlgoliaSearchPagePath = pathByRouteName('AlgoliaSearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than AlgoliaSearchPage too
    const isAlgoliaSearchPage = currentPath === AlgoliaSearchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isAlgoliaSearchPage) {
      const { history, location, filterConfig } = this.props;

      // parse query parameters, including a custom attribute named certificate
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      //const viewportMapCenter = SearchMap.getMapCenter(map);
      const originMaybe = config.sortSearchByDistance ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, filterConfig),
      };

      history.push(createResourceLocatorString('AlgoliaSearchPage', routes, {}, searchParams));
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  render() {
    const {
      intl,
      listings,
      sortConfig,
      filterConfig,
      scrollingDisabled,
      history,
      searchParams,
      currentUser,
      showList,
      categories,
      subCategories,
      onAddOrRemoveToConnected,
      onFetchCurrentCategories,
      categoriesInProgress
      // location,
      // params,
      // pageName,
      // subChildCategories,
      // currentUserListing,
      // currentUserListingFetched,
    } = this.props;

    if (typeof window === undefined) return null;
    const { getAlgoliaResults } = require('@algolia/autocomplete-js');
    // eslint-disable-next-line no-unused-vars
    // const { mapSearch, page, } = parse(location.search, {
    //   latlng: ['origin'],
    //   latlngBounds: ['bounds'],
    // });
    const searchInURL = typeof window != "undefined" ? parse(window.location.search) : {};

    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(
      pickSearchParamsOnly(searchParams, filterConfig, sortConfig)
    );

    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const shouldShowSearchMap =
      !isMobileLayout || (isMobileLayout && this.state.isSearchMapOpenOnMobile);

    const onMapIconClick = () => {
      this.useLocationSearchBounds = true;
      this.setState({ isSearchMapOpenOnMobile: true });
    };
    const handleShowHide = () => {
      if (this.state.showMap) {
        this.setState({ showMap: false })
      } else {
        this.setState({ showMap: true })
      }
    }

    const { address, bounds, origin } = searchInURL || {};
    const { title, description, schema } = createSearchResultSchema(listings, address, intl);

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    const { searchState } = this.state;

    const parameters = {};
    if (!searchState.boundingBox) {
      parameters.aroundLatLngViaIP = true;
      parameters.aroundRadius = 'all';
      parameters.hitsPerPage = 16;
      parameters.filters = 'state:"published" AND (ListingType:"VENDOR_PROFILE" OR ListingType:"VENUE_PROFILE")';
    }

    const redirectTo = (link) => {
      this.onSearchStateChange({
        configure: { aroundLatLngViaIP: true, aroundRadius: 'all', hitsPerPage: 8 },
        page: 1,
        refinementList: link
      });
    }

    const selectedCategory = categories[this.state.selectedCategory];

    const handleSearchChange = (value) => this.setState({ searchState: value });
    const handleSearchSubmit = () => {
      history.push(`/search?${stringify({ ...searchState?.location?.selectedPlace || {}, query: searchState?.query })}`)
    }
    const setdropDownOpen = (value) => { this.setState({ dropDownOpen: value || false }) }

    // Function to create URL to listing
    const createURLToListing = (listing) => {
      return `/l/${listing.objectID}`;
    }

    // Function to handle listing card click
    const onListingInfoCardClicked = (listing) => {
      const { title, categoriesLabel, subCategoriesLabel, region, objectID } = listing.attribute || {}

      const slug = createSlug(title);
      const params = {
        id: objectID,
        slug,
        city: (location && location.address)
          ? createSlug(location.address.split(',')[0])
          : null,
        category: ((categoriesLabel && createSlug(categoriesLabel[0])) || (categories && categories.length && createSlug(categories[0].label))),
        subcategory: ((subCategoriesLabel && createSlug(subCategoriesLabel[0])) || (categories && categories.length && createSlug(categories[0].subCategory[0].label))),
      };

      return history.push(createResourceLocatorString(
      (params && params.id && params.city && params.category && params.subcategory)? 'ListingCityPage' : 'ListingPage',
        routeConfiguration(),
        params
      ));

      // return history.push(`/l/${createSlug(region)}/${createSlug(categoriesLabel[0])}/${createSlug(subCategoriesLabel[0])}/${createSlug(title)}/${objectID}`);
    };

    const settings = {
      className: "center",
      // centerMode: true,
      // infinite: true,
      // centerPadding: "60px",
      slidesToShow: 2,
      speed: 500,
      rows: 2,
      slidesPerRow: 2,
      arrows: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      // variableWidth: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesPerRow: 1,
            
          }
        }
      ]
    };

    const getSelectedCategoryFromUrl = () => {
      if (typeof window !== 'undefined') {
        const params = new URLSearchParams(window.location.search);
        return params.get('refinementList[subCategoriesLabel][0]');
      }
      return null;
    };
  
    const selectedCategoris = getSelectedCategoryFromUrl();

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
        className={css.mainWrapper}
      >
        <TopbarContainer
          className={topbarClasses}
          currentPage="AlgoliaSearchPage"
          currentSearchParams={urlQueryParams}
        />
        <div className={css.container}>
          <div className={classNames(css.aisInstantSearch, (currentUser && currentUser.id) ? "" : css.notLoginUserSearch)}>
            <InstantSearch
              searchClient={searchClient}
              indexName={indexName}
              searchState={searchState}
              onSearchStateChange={this.onSearchStateChange}
            >
              <Configure {...parameters} />
              <div className={classNames(css.leftPanel, this.state.showMap ? css.fullLeftPanel : "")}>
                <div className={css.breadcrumbsBox}>
                  <Breadcrumbs
                    lvl1={{ name: "AlgoliaSearchPage", label: "Search" }}
                  // lvl2={searchInURL && Object.keys(searchInURL).length && searchInURL['refinementList[categoriesLabel]']
                  //   ? { name: "AlgoliaSearchPage", label: searchInURL['refinementList[categoriesLabel]'], search: `refinementList[categoriesLabel]=${searchInURL['refinementList[categoriesLabel]']}` }
                  //   : searchInURL && Object.keys(searchInURL).length && searchInURL['refinementList[categoriesLabel][0]']
                  //     ? { name: "AlgoliaSearchPage", label: searchInURL['refinementList[categoriesLabel][0]'], search: `refinementList[categoriesLabel][0]=${searchInURL['refinementList[categoriesLabel][0]']}` }
                  //     : ''}
                  // lvl3={searchInURL && Object.keys(searchInURL).length && searchInURL['refinementList[subCategoriesLabel][0]']
                  //   ? { name: "AlgoliaSearchPage", label: searchInURL['refinementList[subCategoriesLabel][0]'], search: `refinementList[subCategoriesLabel][0]=${searchInURL['refinementList[subCategoriesLabel][0]']}` }
                  //   : ''}
                  />
                </div>
                <div className={!showList ? css.searchTabs : css.navLinksBox}>
                  {/* <RefinementList attribute="categoriesLabel" transformItems={(items) => {
                    // refinementList%5Bcategories.label%5D%5B0%5D=Catering&page=1&configure%5BaroundLatLngViaIP%5D=true&configure%5BaroundRadius%5D=all&configure%5BhitsPerPage%5D=8
                    // const index = items.findIndex(i => i.isRefined);
                    // if (index > -1) {
                    //   if (!this.state.selectedCategory) {
                    //     this.setState({ selectedCategory: items[index].label });
                    //   }
                    // } else {
                    //   this.setState({ selectedCategory: '' });
                    // }
                    return orderBy(items, ['label', 'count'], ['asc', 'desc'])
                  }} /> */}
                  <div className={css.categoriesList}>
                    {categories?.sort((a, b) => a.name.localeCompare(b.name))?.map((item, index) => {
                      const isSelected = index == this.state.selectedCategory;
                      return <div key={index} className={classNames(css.categoryItem, {
                        [css.selected]: isSelected
                      })} onClick={() => {
                        const payload = {
                          clearPrev: true,
                          parentIds: [index + 1],
                          type: "subCategories"
                        }
                        onFetchCurrentCategories(payload)
                        this.setState({ selectedCategory: index })
                      }}>{item.label}</div>
                    })}
                  </div>
                </div>
                <div>

                  <div className={css.bannerBox}>
                    <ResponsiveImage
                      alt='Event Image'
                      url={selectedCategory?.image || ''}
                      // variants={["scaled-small"]}
                      className={css.bannerImage}
                    />
                    <div className={css.bannerContent}>
                      <div className={css.bannerText}>
                        <h3 className={css.bannerTitle}> {selectedCategory?.label}</h3>
                        <p className={css.bannerDescription}> {selectedCategory?.shortDescription1}</p>
                      </div>
                      <div className={css.mainCover}>
                        <div className={css.searchBar}>
                          <div className={css.searchLeft}>
                            <div className={css.locationDropDown}>
                              <MenuSelect
                                limit={30}
                                attribute={"region"}
                                className={css.searchForm}
                                transformItems={(items) => orderBy(items, ['label', 'count'], ['asc', 'desc'])}
                                translations={{ seeAllOption: intl.formatMessage({ id: "AlgoliaSearchPage.byRegion" }) }}
                              />
                            </div>

                            <div className={css.searchWrapper}>
                              <div className={css.keyWordBox} onClick={() => this.setState({ dropDownOpen: !this.state.dropDownOpen })}>
                                <span className={css.searchIcon}>
                                  <IconProfileSetup type="search" />
                                </span>
                                <AlgoliaAutoCompleteSearchField
                                  className={css.searchKeyword}
                                  onChange={handleSearchChange}
                                />
                              </div>
                              {/* {this.state.dropDownOpen
                                ? <CategoryDropdown
                                  isMobile={isMobile}
                                  categories={categories}
                                  subCategories={subCategories}
                                  dropDownOpen={this.state.dropDownOpen}
                                  setdropDownOpen={setdropDownOpen}
                                  onFetchCurrentCategories={
                                    (payload) => {
                                      onFetchCurrentCategories(payload).then(() => setdropDownOpen(true))
                                    }}
                                />
                                : null} */}

                            </div>
                          </div>

                          <Button
                            className={isMobile ? css.goFullButton : css.goButton}
                            type="button"
                            onClick={handleSearchSubmit}>
                            <FormattedMessage id={isMobile ? "SectionHero.findVendersText" : "SectionHero.goText"} />
                          </Button>

                        </div>
                      </div>
                    </div>
                  </div>

                  {categoriesInProgress
                    ? <SkeletonLoader />
                    : <div className={classNames(css.heroCategoriesBox, !this.state.showMap ? css.fullHeroCategoriesBox : "")}>
                      <Slider {...settings}>
                        {subCategories && subCategories.length
                          ? subCategories.sort((a, b) => ('' + a.label).localeCompare(b.label)).map((item, index) => index < 8 && (
                            <div
                              className={classNames(css.category, { [css.active]: selectedCategoris === item.label })}
                              key={item.key}
                            >
                              <div
                                key={item.key}
                                className={css.heroCategories}
                                onClick={() => {
                                  this.onSearchStateChange([item.label]);
                                  redirectTo({ subCategoriesLabel: [item.label] });
                                }}
                              >
                                {item.image
                                  ? <div className={css.categoriesImage}>
                                    <ResponsiveImage url={item.image} className={css.logoBrand} alt={item.label} />
                                  </div>
                                  : <div className={css.noImage}><FormattedMessage id="SectionHero.noImageText" /></div>}
                                <div className={css.categoriesText}>
                                  <h4>{item.label}</h4>
                                  <p>{item.shortDescription1 ? stringFromLength(item.shortDescription1, 100, true) : ""}</p>
                                </div>
                              </div>
                            </div>
                          ))
                          : null}
                      </Slider>
                    </div>}
                </div>

                <h2 className={css.searchResultsTitle}><FormattedMessage id='AlgoliaSearchPage.searchResults' values={{ totalCount: this.state.totalCount ? '(' + this.state.totalCount + ')' : '' }} /></h2>
                <div className={css.searchFilter}>
                  <div className={css.leftSearchFiler}>
                    <SearchBox
                      translations={{
                        placeholder: 'Keyword',
                      }}
                    />
                    <SortBy
                      defaultRefinement={indexName}
                      items={[
                        { label: 'Relevance', value: indexName },
                        { label: 'A-Z', value: `${indexName}_alphabet_sorting` },
                        { label: 'Recently Joined', value: `${indexName}_recently_joined` },
                      ]}
                    />
                    <MenuSelect
                      limit={30}
                      attribute={"region"}
                      transformItems={(items) => orderBy(items, ['label', 'count'], ['asc', 'desc'])}
                      translations={{ seeAllOption: intl.formatMessage({ id: "AlgoliaSearchPage.byRegion" }) }}
                    />
                    <MenuSelect
                      limit={30}
                      attribute={"city"}
                      transformItems={(items) => orderBy(items, ['label', 'count'], ['asc', 'desc'])}
                      translations={{ seeAllOption: intl.formatMessage({ id: "AlgoliaSearchPage.byCity" }) }}
                    />
                    {/* <div className={css.filterBox}
                      onClick={() => {
                        toggleSecondaryFiltersOpen(!isSecondaryFiltersOpen);
                      }}
                    >
                      <IconProfileSetup type="filter" />
                    </div> */}

                    <ClearRefinements />
                  </div>
                  {this.state.showMap ? <div className={css.mapToogleButton}>
                    <div className={css.mapLabel}>
                      <FormattedMessage id={this.state.showMap ? 'AlgoliaSearchPage.showMap' : 'AlgoliaSearchPage.hideMap'} />
                    </div>
                    <div className={css.checkboxWrapper}
                      onClick={handleShowHide}>
                      <div
                        className={classNames(css.ball, {
                          [css.toggled]: !this.state.showMap,
                        })}
                      >
                        {' '}
                      </div>
                    </div>
                  </div> : null}
                </div>
                <div className={css.selectCategoryData}>
                  <div>
                    <div className="ais-RefinementList-list">
                      <div className={"ais-RefinementList-item " + ((searchInURL && Object.keys(searchInURL).length == 0) || (searchInURL && searchInURL["refinementList[subCategoriesLabel]"] == "") ? "ais-RefinementList-item--selected" : "")} onClick={() => history.push(
                        createResourceLocatorString(
                          'AlgoliaSearchPage',
                          routeConfiguration(),
                          {},
                          {}
                        )
                      )}>
                        <label className="ais-RefinementList-label">
                          <span className="ais-RefinementList-labelText"><FormattedMessage id='AlgoliaSearchPage.all' /></span>
                          <span> </span>
                          <span className="ais-RefinementList-count">
                            {this.state.totalCount ? this.state.totalCount : ''}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <RefinementList attribute="subCategoriesLabel" transformItems={(items) => {
                    return orderBy(items, ['label', 'count'], ['asc', 'desc']);
                  }} />
                </div>
                <div className={css.selectCategoryData} style={{ display: 'none' }}>
                  <RefinementList attribute="location.address" transformItems={(items) => {
                    []
                    const totalCount = items.map(i => i.count).reduce((accu, curr) => accu + curr, 0);
                    if (!this.state.totalCount) {
                      this.setState({ totalCount });
                    }
                    return orderBy(items, ['label', 'count'], ['asc', 'desc']);
                  }} />
                </div>

                <div className={css.dividerLine} />
                <div className={classNames(
                  css.cardsGrid, this.state.showMap ? css.fullCardGrid : "")}>
                  <InfiniteHits hitComponent={(props) => <ListingCard
                    {...props}
                    currentUser={currentUser}
                    categories={categories}
                    subCategories={subCategories}
                    onAddOrRemoveToConnected={onAddOrRemoveToConnected}
                  />} />
                </div>
              </div>
              {this.state.showMap || isMobileLayout
                ? null
                : <div className={css.rightPanel}>
                  <div className={css.searchMapInput}>
                    <AlgoliaAutoComplete
                      insights={true}
                      openOnFocus={true}
                      defaultActiveItemId={0}
                      debug
                      getSources={({ query }) => [
                        {
                          sourceId: 'location.address',
                          getItems() {
                            return getAlgoliaResults({
                              searchClient,
                              queries: [
                                {
                                  indexName: 'DEVListings',
                                  query,
                                },
                              ],
                            });
                          },
                          templates: {
                            item({ item, components }) {
                              // return <span
                              //   onClick={() => redirectTo(item.location.address)}
                              //   className="aa-ItemLink">
                              //   {item.location.address}
                              // </span>
                              return <AlgoliaAutoItem hit={item} redirectTo={redirectTo} components={components} />

                            },
                          },
                          getItemInputValue({ item }) {
                            return item.location.address;
                          },
                        },
                      ]}
                    />
                  </div>
                  {/* </div> */}
                  <div className={css.mapRightBar}>
                    <GoogleMapsLoader apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}>
                      {/* "AIzaSyBawL8VbstJDdU5397SUX7pEt9DslAwWgQ" */}
                      {(google) => (
                        <GeoSearch google={google} enableRefineOnMapMove={false}>
                          {({ hits }) => {
                            return (
                              // <Fragment>
                              //   <Control />
                              hits.map((hit) => (
                                // <Marker key={hit.objectID} hit={hit} />
                                <CustomMarker key={hit.objectID} hit={hit}>
                                  <div className={css.iconWrapper}>
                                    <span style={{ fontSize: "1rem" }}>
                                      <IconMapMarker brand="markerone" />
                                    </span>
                                    <div className={css.hoverCard}>
                                      <SearchMapInfoCard
                                        listings={[hit]}
                                        createURLToListing={createURLToListing}
                                        onListingInfoCardClicked={onListingInfoCardClicked}
                                        intl={intl}
                                      />
                                      {/* <div className={css.caret} /> */}
                                    </div>
                                  </div>
                                </CustomMarker>
                              ))
                              // </Fragment>

                            )
                          }}
                        </GeoSearch>
                      )}
                    </GoogleMapsLoader>
                    <div className={css.mapInsideToogleButton}>

                      <div className={css.checkboxWrapper}
                        onClick={handleShowHide}>
                        <div
                          className={classNames(css.ball, {
                            [css.toggled]: !this.state.showMap,
                          })}
                        >
                          {' '}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={css.rangeSliderBox}>
                    <div className={css.sliderHead}>
                      <div className={css.radioName}>
                        <FormattedMessage id='AlgoliaSearchPage.adjustRadius' />
                      </div>
                      <div className={css.checkboxWrapper}
                        onClick={() => this.handleToggleState()}>
                        <div
                          className={classNames(css.ball, {
                            [css.toggled]: this.state.toggle,
                          })}
                        >
                          {' '}
                        </div>
                      </div>
                    </div>
                    <SliderTiles
                      rangeValue={this.state.rangeValue}
                      setRangeValue={(e) => { this.setState({ rangeValue: [e] }) }}
                    />
                  </div>
                </div>}
            </InstantSearch>
          </div>
        </div >
        <Footer />
      </Page >
    );
  }
}

AlgoliaSearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  activeListingId: null,
  initialSearchFormValues: {},
};

AlgoliaSearchPageComponent.propTypes = {
  // listings: array,
  // mapListings: array,
  // onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  // onSearchMapListings: func.isRequired,
  // pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  // searchInProgress: bool.isRequired,
  // searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,
  initialSearchFormValues: object,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    categories,
    subCategories,
    subChildCategories,
    categoriesInProgress
  } = state.user;

  return {
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    categories,
    subCategories,
    categoriesInProgress,
    subChildCategories,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onFetchCurrentCategories: (payload) => dispatch(fetchCurrentCategories(payload)),
  onAddOrRemoveToConnected: (listingId, isAddedPreferred, businessName) => dispatch(addOrRemoveToConnected(listingId, isAddedPreferred, businessName))
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AlgoliaSearchPage = compose(
  withRouter,
  withViewport,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(AlgoliaSearchPageComponent);

export default AlgoliaSearchPage;
